import React from "react"
import "bulma/css/bulma.css"
import "../styles/stronaPracownika.scss"
import _navigation from "../components/_navigation"
import Footer from "../components/footer"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ArrowBack from "@material-ui/icons/ArrowBack"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import ReactPlayer from "react-player"
import ImageGallery from "react-image-gallery"

export const query = graphql`
  query($slug: String!) {
    contentfulPracownicy(slug: { eq: $slug }) {
      childContentfulPracownicyOpisLekarzaRichTextNode {
        json
      }
      zdjecie {
        fixed {
          src
        }
      }
      imie
      rola
      recepcja
      slug
      sympozjaIKonferencje {
        file {
          url
        }
      }
      warsztatyISzkolenia {
        file {
          url
        }
      }
    }
  }
`
const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const opis = node.data.target.fields.description.pl
      const url = node.data.target.fields.file.pl.url
      const width = node.data.target.fields.file.pl.details.image.width
      const height = node.data.target.fields.file.pl.details.image.height
      return node.data.target.fields ? (
        <React.Fragment>
          <br clear="left" />
          <img
            style={{ display: "block", margin: "0 auto" }}
            className="img-fluid"
            vspace="20"
            title={opis}
            height={height < 100 ? height * 1.3 : height}
            width={width < 300 ? width * 1.3 : width}
            alt={opis}
            src={url}
          />
        </React.Fragment>
      ) : (
        <div></div>
      )
    },
  },
  renderText: text => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderMark: {
    [MARKS.CODE]: embedded => <ReactPlayer url={embedded[1]} />,
  },
}
const StronaPracownika = props => {
  const sympozja = props.data.contentfulPracownicy.sympozjaIKonferencje
  const warsztaty = props.data.contentfulPracownicy.warsztatyISzkolenia
  const arrayZdjecSympozja = []
  const arrayZdjecWarsztaty = []
  sympozja &&
    sympozja.map(zdjecie =>
      arrayZdjecSympozja.push({
        original: zdjecie.file.url,
        thumbnail: zdjecie.file.url,
      })
    )
  warsztaty &&
    warsztaty.map(zdjecie =>
      arrayZdjecWarsztaty.push({
        original: zdjecie.file.url,
        thumbnail: zdjecie.file.url,
      })
    )
  const zdjecie = props.data.contentfulPracownicy.zdjecie.fixed.src
  const imie = props.data.contentfulPracownicy.imie
  const body =
    props.data.contentfulPracownicy.childContentfulPracownicyOpisLekarzaRichTextNode &&
    props.data.contentfulPracownicy.childContentfulPracownicyOpisLekarzaRichTextNode.json &&
    documentToReactComponents(
      props.data.contentfulPracownicy.childContentfulPracownicyOpisLekarzaRichTextNode.json,
      options
    )
  return (
    <div>
      <_navigation />
      <section className="hero hero-small-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title kat-glowna-tytul">{imie}</h1>
          </div>
        </div>
      </section>
      <section className="section columns is-vcentered strona-pracownika">
        <img src={zdjecie} className="column auto" alt="" />
        <div className="column is-6-desktop is-10-tablet container-opis-body-pracownik">{body}</div>
        {arrayZdjecSympozja.length > 0 && (
          <div className="column is-6-desktop is-8-tablet is-12-mobile certyfikaty-galeria">
            <h3>Sympozja i Konferencje</h3>
            <ImageGallery style={{ marginTop: "50px" }} className="column" items={arrayZdjecSympozja} />
          </div>
        )}
        {arrayZdjecWarsztaty.length > 0 && (
          <div className="column is-6-desktop is-8-tablet is-12-mobile certyfikaty-galeria">
            <h3>Warsztaty i Szkolenia</h3>
            <ImageGallery style={{ marginTop: "50px" }} className="column" items={arrayZdjecWarsztaty} />
          </div>
        )}
      </section>
      <div className="button-wrapper-wstecz">
        <Link to={"/"}>
          <button className="button button-wstecz-promocje is-primary is-light">
            <ArrowBack /> Wstecz
          </button>
        </Link>
      </div>
      <Footer />
    </div>
  )
}
export default StronaPracownika
